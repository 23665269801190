import React, { useEffect, useState } from 'react';
import request from '../api/request';
import { ADD_TO_CART } from '../api/ApiConstants';
import MiniCart from '../pages/Cart/MiniCart';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const AppContext = React.createContext();

function AppProvider(props) {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [showMinicart, setShowMiniCart] = useState(false);
	const [cartItems, setCartItems] = useState([]);
	const [fullCartData, setFullCartData] = useState([]);

	const addToCart = async (payload, mini) => {
		await request(ADD_TO_CART, { method: 'POST', payload }).then((response) => {
			if (response.data) {
				// setCartItems([...cartItems, response.data]);
				getCartItems(mini);
				// toast.success("Item has been added into Cart Successfully!", {
				//   position: "top-center",
				// });
			}
		});
	};

	const clearAllCart = async () => {
		await request('clearAllItemsFromCart', {
			method: 'DELETE'
		}).then((response) => {
			if (response.data) {
				setCartItems([]);
				setFullCartData({});
				toast.success('All cart items were got cleared!', {
					position: 'top-center'
				});
			}
		});
	};

	const getCartItems = async (mini) => {
		await request('listUserCart').then((response) => {
			setCartItems(response.data.userCartList || []);
			setFullCartData(response.data);
			setShowMiniCart(mini || false);
		});
	};

	const gotoLoginPage = (navigate) => {
		localStorage.setItem('reditectUrl', window.location.href);
		// history.push("/#/auth/login");
		// window.location.reload();
		navigate('/auth/login');
	};

	const gotoPage = (page, navigate) => {
		// window.location.href = "/#/" + page;
		setShowMiniCart(false);
		navigate(`/${page}`);
	};

	useEffect(() => {
		if (window && window.localStorage.getItem('token')) {
			setIsAuthenticated(true);
			getCartItems();
		}
	}, []);

	return (
		<AppContext.Provider
			value={{
				isAuthenticated,
				setIsAuthenticated,
				cartItems,
				addToCart,
				getCartItems,
				gotoLoginPage,
				clearAllCart,
				setShowMiniCart,
				fullCartData
			}}>
			{props.children}
			{showMinicart && (
				<MiniCart
					cartItems={cartItems || []}
					gotoPage={gotoPage}
				/>
			)}
		</AppContext.Provider>
	);
}

AppProvider.propTypes = {
	children: PropTypes.any
};

const AppConsumer = AppContext.Consumer;

export { AppContext, AppProvider, AppConsumer };
