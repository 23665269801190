import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Translator from './Translator';

function Alert() {
	return (
		<CookieConsent
			buttonText={<Translator textKey='Header.tyi' />}
			style={{ zIndex: 999999999 }}>
			<Row>
				<Col md={10}>
					<Translator textKey='Header.tyu' />
				</Col>
				<Col md={2}>
					<Link to='/privacy'>
						<button
							className='more-con'
							type='button'>
							{' '}
							<Translator textKey='Header.tys' />
						</button>
					</Link>
				</Col>
			</Row>
		</CookieConsent>
	);
}

export default Alert;
