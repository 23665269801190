/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import '../assets/css/Header.css';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/images/logo.svg';
import Translator from './Translator';
import Image from './Internal/Image';
import bag from '../assets/images/escart.svg';
import LanguageSwitch from './Internal/LanguageSwitch';
import Pro from '../assets/images/pro.svg';
import { AppContext } from '../Context/AppContext';

const Header = () => {
	const location = useLocation();
	const { isAuthenticated, setShowMiniCart } = useContext(AppContext);
	const [hideHeader, setHideHeader] = useState(true);
	const [cartHeader, setCartHeader] = useState(false);
	const [activeLink, setActiveLink] = useState('');
	const [, setDummyObj] = useState('');
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const now = new Date();
	const gotoLogin = () => {
		localStorage.setItem('reditectUrl', window.location.href);
	};

	useEffect(() => {
		console.log(location);
		setShowMiniCart(false);
		setHideHeader(location.pathname.indexOf('/auth/') !== -1);
		setCartHeader(location.pathname.indexOf('/cart') !== -1);
		setDummyObj(new Date());
	}, [location]);

	useEffect(() => {
		setHideHeader(window.location.href.indexOf('/auth/') !== -1);
		setCartHeader(window.location.href.indexOf('/cart') !== -1);
		if (window.location.href.indexOf('/') !== -1) setActiveLink('/');
		if (window.location.href.indexOf('/shop') !== -1) setActiveLink('/shop');
	}, [now]);

	const getActiveLink = (name) => {
		return activeLink === name || window.location.href.indexOf(name) !== -1 ? ' active' : '';
	};

	if (hideHeader) return null;
	if (cartHeader) {
		return (
			<Navbar
				collapseOnSelect
				expand='lg'
				className='navbar'>
				<Navbar.Brand>
					<Link
						to={'/' + getActiveLink('/')}
						onClick={() => setActiveLink('/')}>
						<Image
							img={logo}
							className='cart-head-logo'
							alt='...'
						/>
					</Link>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls='responsive-navbar-nav' />
				<Navbar.Collapse id='responsive-navbar-nav'>
					<div className='continue-head'>
						<Link to='/shop'>
							{' '}
							<i className='fas fa-arrow-left'></i>{' '}
						</Link>
						&nbsp;
						<Link
							to='/shop'
							className='arrow-login'
							md={6}
							xs={12}>
							<Translator
								className='back-home'
								textKey='Header.continueShopping'
							/>
						</Link>
						<Link
							to='/my-account'
							className='nav-link-img '>
							<Image img={Pro} />
						</Link>
					</div>
				</Navbar.Collapse>
			</Navbar>
		);
	}

	return (
		<Navbar
			collapseOnSelect
			expand='lg'
			className='navbar'>
			<Navbar.Brand>
				<Link to='/'>
					<img
						src={logo}
						className='logo'
						alt='...'
					/>
				</Link>
			</Navbar.Brand>
			<Navbar.Toggle aria-controls='responsive-navbar-nav' />
			<Navbar.Collapse id='responsive-navbar-nav'>
				<Nav className='me-auto nav-main'>
					<Link
						to='/whatisdots'
						className={'nav-link' + getActiveLink('/whatisdots')}
						onClick={() => setActiveLink('/whatisdots')}>
						{' '}
						<Translator textKey='Header.what' />
					</Link>
					<Link
						to='/shop'
						className={'nav-link' + getActiveLink('/shop')}
						onClick={() => setActiveLink('/shop')}>
						<Translator textKey='Header.shop' />
					</Link>
					<Link
						to='/customize'
						className={'nav-link' + getActiveLink('/customize')}
						onClick={() => setActiveLink('/customize')}>
						<Translator textKey='Header.customize' />
					</Link>
					<Link
						to='/enterprise'
						className={'nav-link' + getActiveLink('/enterprise')}
						onClick={() => setActiveLink('/enterprise')}>
						<Translator textKey='Header.enterprise' />
					</Link>
					<Link
						to='/reseller'
						className={'nav-link' + getActiveLink('/reseller')}
						onClick={() => setActiveLink('/reseller')}>
						<Translator textKey='Header.reseller' />
					</Link>
					<Link
						to='/ambassador'
						className={'nav-link' + getActiveLink('/ambassador')}
						onClick={() => setActiveLink('/ambassador')}>
						<Translator textKey='Header.ambassador' />
					</Link>
					<Link
						to='/help'
						className={'nav-link' + getActiveLink('/help')}
						onClick={() => setActiveLink('/help')}>
						<Translator textKey='Header.help' />
					</Link>
					<Link
						to='/contact'
						className={'nav-link' + getActiveLink('/contact')}
						onClick={() => setActiveLink('/contact')}>
						{' '}
						<Translator textKey='Header.contact' />
					</Link>
				</Nav>
				<Nav className='ml-auto '>
					{/* <Link to="/" className="nav-links "><Image img={search}/></Link> */}
					{isAuthenticated && (
						<>
							<Link
								to='/my-account'
								className='nav-links '>
								<Image img={Pro} />
							</Link>
						</>
					)}
					<LanguageSwitch />
					{isAuthenticated ? (
						<Fragment>
							<Link
								to='/cart'
								className='nav-links '>
								<Image
									img={bag}
									className='cart-img'
								/>
							</Link>
						</Fragment>
					) : (
						<>
							<Link
								to='/auth/login'
								onClick={gotoLogin}>
								<button
									className='head-btnn'
									type='button'>
									<Translator textKey='Header.signin' />
								</button>
							</Link>
							<Link to='/auth/register'>
								<button
									className='head-btn'
									type='button'>
									<Translator textKey='Header.signup' />
								</button>
							</Link>
						</>
					)}
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

export default Header;
