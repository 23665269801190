import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import Image from '../../components/Internal/Image';
import bag from '../../assets/images/icons/bag-w.png';
import Translator from '../../components/Translator';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../../assets/css/Cart.css';

const MiniCart = ({ cartItems, gotoPage }) => {
	let totalAmount = 0;
	let totalItems = 0;
	const navigate = useNavigate();

	cartItems.forEach((c) => {
		totalAmount = totalAmount + c.productType.chipCost * c.quantity;
		totalItems = totalItems + parseInt(c.quantity);
	});

	const goToCart = function () {
		gotoPage('cart', navigate);
	};
	return (
		<Fragment>
			<Row className='mini-cart  text-white'>
				<Col
					md={4}
					className='text-center item-add'>
					<Image
						img={bag}
						className='mini-bag'
					/>
					&nbsp;&nbsp;&nbsp;
					<Translator textKey='Cart.cart' /> ( {totalItems || '0'}{' '}
					<Translator textKey={totalItems > 1 ? 'Cart.items' : 'Cart.item'} /> )
				</Col>
				<Col
					md={8}
					className='text-center'>
					<Translator textKey='Cart.subtotal' /> {totalAmount}
					<Link
						to='/cart'
						onClick={goToCart}
						className='btn btn-primary mini-cart-continue ml-3'>
						<Translator textKey='Common.continue' />
					</Link>
				</Col>
			</Row>
		</Fragment>
	);
};

MiniCart.propTypes = {
	cartItems: PropTypes.array,
	gotoPage: PropTypes.func
};

export default MiniCart;
