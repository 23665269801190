import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const Translator = ({ textKey, className }) => {
	// const { textKey } = props;
	const { t } = useTranslation();
	const text = t(textKey);

	if (className) return <span className={className}>{text}</span>;
	return text;
};

Translator.propTypes = {
	textKey: PropTypes.string,
	className: PropTypes.string
};

export default Translator;
