import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import { AppProvider } from './Context/AppContext';
import './assets/css/style.css';
import './assets/css/menu.css';
import './i18n';
import 'react-toastify/dist/ReactToastify.css';
import { Route, createRoutesFromElements, createHashRouter, Navigate, RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

const Home = lazy(() => import('./pages/Home/Home'));
const Customize = lazy(() => import('./pages/Customize/Customize'));
const CustomizeProductInfo = lazy(() => import('./pages/Customize/CustomizeProductInfo'));
const Enterprise = lazy(() => import('./pages/Home/Enterprise'));
const Reseller = lazy(() => import('./pages/Home/Reseller'));
const Ambassador = lazy(() => import('./pages/Home/Ambassador'));
const Help = lazy(() => import('./pages/Home/Help/Help'));
const Shop = lazy(() => import('./pages/Shop/Shop'));
const ProductInfo = lazy(() => import('./pages/Shop/ProductInfo'));
const MyAccount = lazy(() => import('./pages/MyAccount/MyAccount'));
const Login = lazy(() => import('./pages/Auth/Login'));
const ForgotPassword = lazy(() => import('./pages/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/Auth/ResetPassword'));
const Register = lazy(() => import('./pages/Auth/Register'));
const Terms = lazy(() => import('./pages/Home/Terms'));
const Privacy = lazy(() => import('./pages/Home/Privacy'));
const AboutUs = lazy(() => import('./pages/Home/Aboutus'));
const Deliverys = lazy(() => import('./pages/Home/Deliverys'));
const Distance = lazy(() => import('./pages/Home/Distance'));
const Contact = lazy(() => import('./pages/Home/Contact'));
const CartPage = lazy(() => import('./pages/Cart/CartPage'));
const Upload = lazy(() => import('./pages/Customize/CustomUpload'));
const Whatisdots = lazy(() => import('./pages/Home/Whatisdots'));
const Error = lazy(() => import('./pages/Error/error'));

const router = createHashRouter(
	createRoutesFromElements(
		<Route element={<App />}>
			<Route
				index
				element={<Home />}></Route>
			<Route
				exact
				path='/auth/login'
				element={<Login />}></Route>
			<Route
				exact
				path='/auth/register'
				element={<Register />}></Route>
			<Route
				exact
				path='/auth/forgot-password'
				element={<ForgotPassword />}></Route>
			<Route
				exact
				path='/auth/reset-password'
				element={<ResetPassword />}></Route>
			<Route
				exact
				path='/whatisdots'
				element={<Whatisdots />}></Route>
			<Route
				exact
				path='/customize'
				element={<Customize />}></Route>
			<Route
				exact
				path='/customize/:id'
				element={<CustomizeProductInfo />}></Route>
			<Route
				exact
				path='/upload'
				element={<Upload />}></Route>
			<Route
				exact
				path='/enterprise'
				element={<Enterprise />}></Route>
			<Route
				exact
				path='/reseller'
				element={<Reseller />}></Route>
			<Route
				exact
				path='/ambassador'
				element={<Ambassador />}></Route>
			<Route
				exact
				path='/help'
				element={<Help />}></Route>
			<Route
				exact
				path='/shop'
				element={<Shop />}></Route>
			<Route
				exact
				path='/shop/:catId'
				element={<Shop />}></Route>
			<Route
				exact
				path='/product/:id'
				element={<ProductInfo />}></Route>
			<Route
				exact
				path='/my-account'
				element={<MyAccount />}></Route>
			<Route
				exact
				path='/my-account/:pageType'
				element={<MyAccount />}></Route>
			<Route
				exact
				path='/cart'
				element={<CartPage />}></Route>
			<Route
				exact
				path='/terms'
				element={<Terms />}></Route>
			<Route
				exact
				path='/privacy'
				element={<Privacy />}></Route>
			<Route
				exact
				path='/aboutus'
				element={<AboutUs />}></Route>
			<Route
				exact
				path='/deliverys'
				element={<Deliverys />}></Route>
			<Route
				exact
				path='/distance'
				element={<Distance />}></Route>
			<Route
				exact
				path='/contact'
				element={<Contact />}></Route>
			<Route
				path='/404'
				element={<Error />}></Route>
			{/* <AuthenticatedRoute path="/cart" component={Cart} /> */}
			<Route
				path='*'
				element={<Navigate to='/404' />}
			/>
		</Route>
	)
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		{/* <AppProvider> */}
		<HelmetProvider>
			<RouterProvider router={router} />
		</HelmetProvider>
		{/* </AppProvider> */}
	</React.StrictMode>
);
