/* eslint-disable space-before-function-paren */
import React, { Suspense, useEffect } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import { useSearchParams, ScrollRestoration, Outlet } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/shop.css';
import { getUrlSearchParams } from './Services/UtilService';
import lang from 'i18next';
import Alert from './components/Alert';
import WhatsAppWidget from 'react-whatsapp-chat-widget';
import 'react-whatsapp-chat-widget/index.css';
import { ToastContainer } from 'react-toastify';
import { AppProvider } from './Context/AppContext';

function App() {
	const [searchParams] = useSearchParams();
	const query = getUrlSearchParams(searchParams);

	useEffect(() => {
		if (query?.lang) {
			lang.changeLanguage(query.lang.toUpperCase());
		}
	}, [query]);

	return (
		<AppProvider>
			<Suspense fallback={<Spinner />}>
				<div className='App'>
					<Header />
					<Alert />
					<ScrollRestoration />
					<Outlet />
					<WhatsAppWidget
						phoneNo='902167553619'
						position='right'
						autoOpen={false}
						messageBox={false}
						iconSize='40'
					/>
					<Footer />
					<ToastContainer
						position='top-center'
						autoClose={5000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
						theme='light'
					/>
				</div>
			</Suspense>
		</AppProvider>
	);
}

export default App;
